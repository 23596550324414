<template>
  <div class="rounded-3">
    <div class="row for-sm">
      <div class="col-md-8">
        <div class="card-header text-style card-header-radius d-flex align-items-center">
          {{ $t('advertising.history') }}
        </div>
        <div class="card-header pb-0" style="border: none; padding: 2rem;">
          <div class="row" style="row-gap: 10px;">
            <div class="col-12 col-sm-4">
              <Multiselect v-model="state.search.facilityId" :options="state.allFacilities"
                class="multiselect-input flex-1" style="padding: 0" :track-by="'name'" :label="'name'" :multiple="false"
                :searchable="true" :can-clear="true" :can-deselect="true" :object="true"
                :placeholder="$t('placeholder.ads_log')">
                <template #noresults>
                  <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                </template>
                <template #nooptions>
                  <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                </template>
              </Multiselect>
            </div>
            <div class="col-12 col-sm-3">
              <div class="position-relative form-icon right">
                <flat-pickr ref="datePickerSearch" v-model="state.search.range" :config="state.rangeDateConfig"
                  :placeholder="$t('t-period')" class="form-control h-100">
                </flat-pickr>
                <i class="bx bx-calendar-alt cursor-pointer" @click="showDateTime('datePickerSearch')"></i>
              </div>
            </div>
            <div class="col-6 col-sm">
              <b-button variant="primary" class="waves-effect waves-light" @click="handleSearch">
                <div class="flex">
                  {{ $t("t-search") }}
                </div>
              </b-button>
            </div>
            <div class="col-6 col-sm-3">
              <div class="dataTables_length">
                <label class="d-flex justify-content-end align-items-center" style="column-gap: 5px; font-size: 12px">
                  <select v-model="state.query.pageSize" name="example_length" aria-controls="example"
                    class="form-select form-control" style="width: 85px" @change="handleChangePerPage">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <span style="min-width: 65px">{{ $t('entries_page') }}</span>
                </label>
              </div>
            </div>
          </div>

        </div>
        <div class="table-responsive card">
          <TableCommon ref="tableAds" :columns="columns" :endpoint="`${ADMIN_API_PATH.ADMIN_ADS}/v2/daily`"
            :query="state.query" :hasOption="false" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="card-header card-header-radius text-style d-flex justify-content-between align-items-center">
          {{ $t('t-log-export') }}
        </div>
        <div class="px-5 py-4 card">
          <div>
            <label for="">施設名称（施設ID）<span class="text-danger">*</span></label>
            <Multiselect v-model="state.export.facilityId" :options="state.optionFacilities" @open="optionsFacilities"
              class="multiselect-input flex-1" style="padding: 0" :track-by="'name'" :label="'name'" :multiple="false"
              :searchable="true" :can-clear="false" :can-deselect="true" :object="true"
              :placeholder="'施設名称（施設ID）を選択してください'">
              <template #noresults>
                <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
              </template>
              <template #nooptions>
                <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
              </template>
            </Multiselect>
          </div>
          <div v-if="v$.facilityId.required.$invalid && invalid" class="error-text-show">
            {{ $t('field_select_required', { field: '施設名称（施設ID）' }) }}
          </div>

          <div class="mt-3">
            <label for="">期間<span class="text-danger">*</span></label>
            <div class="position-relative dateInput w-100 form-icon right">
              <flat-pickr ref="datePickerExport" v-model="state.export.range" :config="state.rangeDateConfig"
                placeholder="期間を選択してください" class="form-control h-100">
              </flat-pickr>
              <i class="bx bx-calendar-alt cursor-pointer" @click="showDateTime('datePickerExport')"></i>
            </div>
          </div>
          <div v-if="v$.range.required.$invalid && invalid" class="error-text-show">
            {{ $t('msg.pleaseSelect', { field: '期間' }) }}
          </div>

          <div class="mt-3">
            <label for="">広告タイプ <span class="text-danger">*</span></label>
            <Multiselect v-model="state.export.type" :options="state.optionsExport" class="multiselect-input flex-1"
              style="padding: 0" :track-by="'name'" :label="'name'" :multiple="false" :searchable="true"
              :can-clear="false" :can-deselect="true" :placeholder="' 広告タイプを選択してください'">
              <template #noresults>
                <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
              </template>
              <template #nooptions>
                <div class="d-flex justify-content-center m-2">{{ $t('t-list-empty') }}</div>
              </template>
            </Multiselect>
          </div>
          <div v-if="v$.type.required.$invalid && invalid" class="error-text-show">
            {{ $t('field_select_required', { field: '広告タイプ' }) }}
          </div>

          <div class="mt-4">
            <b-button variant="primary" class="waves-effect waves-light w-100" @click="handleExport" :disabled="state.loadingExport">
              <div class="d-flex justify-content-center">
                <div class="mr-2" v-if="state.loadingExport">
                  <Loading :width="14" :height="14" />
                </div>
                {{ $t("t-export") }}
              </div>
            </b-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { computed, h, onMounted, reactive, ref } from 'vue'
import { adsService } from '@/services'
import Loading from "@/components/Loading.vue";
import { convertScToTimeFormat } from '@/helpers/moment';
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import i18n from '@/i18n'
import TableCommon from "@/components/common/TableCommonVue3.vue";
import { ADMIN_API_PATH, EXPORT_ADS_HISTORY } from "@/constants";
import * as moment from "moment";
import Multiselect from "@vueform/multiselect";
import { ADS_TYPE } from "@/constants";
import useVuelidate from "@vuelidate/core";
import { debounce } from 'lodash';
import { generateImageOss } from "@/helpers/axios/formDataRequest";

export default {
  computed: {
    ADMIN_API_PATH() {
      return ADMIN_API_PATH
    }
  },
  components: {
    Loading,
    flatPickr,
    TableCommon,
    Multiselect
  },
  setup() {
    const state = reactive({
      query: {
        pageSize: 10,
        facilityId: '',
        startDay: '',
        endDay: ''
      },
      startDay: '',
      endDay: '',
      range: '',
      pagination: null,
      loading: false,
      rows: [],
      tabIdActive: '',
      valueDate: '',
      mode: 'all',
      rangeDateConfig: {
        mode: "range",
        dateFormat: "Y-m-d",
      },
      optionFacilities: [],
      search: {
        range: '',
        facilityId: null
      },
      export: {
        range: '',
        facilityId: '',
        type: ''
      },
      optionsExport: [
        { value: 1, name: "TOKYO SAUNIST 広告" },
        { value: 2, name: "Ultra FreakOut 広告" }
      ],
      loadingExport: false,
      allFacilities: []
    })

    const openLink = debounce((filename) => {
      const path = generateImageOss(filename)
      if (path) {
        window.open(path, '_blank')
      }
    }, 500)

    const columns = [
      {
        sortable: false,
        label: '',
        type: 'stt',
        getShowColumn: (hasItem) => hasItem,
        renderCell: (row, rowIndex) => {
          return h('span', rowIndex + 1)
        }
      },
      {
        key: 'advertisement.ads_id',
        sortKey: 'ads_uuid',
        sortable: true,
        label: i18n.global.t('advertising.ads_id')
      },
      {
        key: 'ads_name',
        sortKey: 'ads_name',
        sortable: true,
        label: i18n.global.t('advertising.ads_name')
      },
      {
        key: 'ads_url',
        renderCell: (row) => h('span', { class: 'btn-link text-decoration-none cursor-pointer', onClick: () => openLink(formatPath(row.advertisement.url)) }, formatPath(row.advertisement.url)),
        sortable: false,
        label: i18n.global.t('advertising.ads_url'),
        headerClass: 'margin-header'
      },
      {
        key: 'displayAmountDaily',
        sortable: false,
        renderCell: row => h('span', ADS_TYPE[row.advertisement.type]),
        label: i18n.global.t('advertising.ads_type'),
        headerClass: 'margin-header'
      },
      {
        key: 'display_amount_daily',
        sortable: true,
        renderCell: row => h('span', convertNumberToTime(row.display_amount_daily)),
        label: i18n.global.t('advertising.displayTime')
      },
      {
        key: 'display_time_daily',
        sortable: true,
        label: i18n.global.t('advertising.count_view')
      },
      {
        key: 'room_count',
        sortable: true,
        label: i18n.global.t('advertising.number_of_devices')
      },
    ]

    const formatPath = (path) => {
      return path.startsWith('/') ? path.slice(1): path
    }

    const convertNumberToTime = (number) => {
      const duration = moment.duration(number, 'seconds');

      let hours = duration.hours();
      let minutes = duration.minutes();
      let seconds = duration.seconds();

      hours = hours === 0 ? '' : `${hours}h`
      minutes = minutes === 0 ? '' : `${minutes}m`

      return `${hours}${minutes}${seconds}s`
    }

    const paginate = (pageNumber) => {
      state.query.page = pageNumber;
    }

    const handleChangePerPage = (e) => {
      state.query.page = 1
    }

    const labelText = computed(() => {
      if (state.mode == 'all') return i18n.global.t('advertising.select_all')
      else return i18n.global.t('advertising.deadline')
    })

    const handleExportLog = async (value) => {
      let { endDay, startDay } = state.query;
      console.log(state.query)
      if (startDay === '') {
        startDay = moment().format('YYYY-MM-DD');
      }
      if (endDay === '') {
        endDay = moment(startDay).format('YYYY-MM-DD');
      }
      let res = ''
      const anchorLogs = document.createElement('a');

      state.loadingExport = true;
      try {
        if (value === 'external') {
          res = await adsService.exportExternalLog({ endDay, startDay });
        } else {
          res = await adsService.exportInternal({ endDay, startDay });
        }

        const day = moment().format('YYYYMMDDhmmss');
        anchorLogs.download = `再生履歴_${day}`;
        anchorLogs.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(res);
        anchorLogs.target = '_blank';
        await anchorLogs.click();

      } catch (e) {
        this.$store.commit('settings/SET_POPUP', {
          show: true,
          type: "error",
          message: "ダウンロードエラー"
        });
      } finally {
        state.loadingExport = false;
      }
    }

    onMounted(() => {
      optionsFacilities();
      optionsAllFacilities();
    })

    const optionsAllFacilities = async () => {
      try {
        state.allFacilities = await adsService.getFacilitiesOptionsPlayList({ facType: 'ALL' });
        state.allFacilities = state.allFacilities.map(s => ({ ...s, facilityIdStr: s.facUuid }));
        state.allFacilities.unshift({
          name: "全て",
          value: 'all',
          facilityIdStr: 'all'
        })
      } catch (e) {
        console.log(e, 'error option facilities')
      }
    }

    const optionsFacilities = async () => {
      try {
        state.optionFacilities = await adsService.getFacilitiesOptionsPlayList();
        state.optionFacilities = state.optionFacilities.map(s => ({ ...s, facilityIdStr: s.facUuid }))
        state.optionFacilities.unshift({
          name: "全て",
          value: 'all',
          facilityIdStr: 'all'
        })
      } catch (e) {
        console.log(e)
      }
    }

    const handleSearch = () => {
      const dateRange = state.search.range.split('to');
      const [startDay, endDay] = dateRange;

      state.query.facilityId = state.search.facilityId?.facilityIdStr || '';
      state.query.startDay = startDay ? startDay.trim() : '';
      state.query.endDay = endDay ? endDay.trim() : '';
    }

    const formData = computed(() => state.export)

    const v$ = useVuelidate(EXPORT_ADS_HISTORY, formData);
    const invalid = ref(null);

    const handleExport = async () => {
      v$.value.$touch();
      invalid.value = v$.value.$invalid;
      if (invalid.value) {
        return
      }
      const dateRange = state.export.range.split('to');
      const [start, end] = dateRange;

      const startDay = start ? start.trim() : moment().format('YYYY-MM-DD');
      const endDay = end ? end.trim() : moment(startDay).format('YYYY-MM-DD');

      let res = ''
      const anchorLogs = document.createElement('a');
      const facilityId = state.export.facilityId?.facilityIdStr || '';

      const payload = { startDay, endDay, facilityId };
      state.loadingExport = true;
      try {
        const showEnd = end ? `_${end}` : '';
        if (state.export.type === 1) {
          res = await adsService.exportInternal(payload);
          anchorLogs.download = `TS_再生履歴_${start}${showEnd}`;
        } else {
          res = await adsService.exportExternalLog(payload);
          anchorLogs.download = `UF_再生履歴_${start}${showEnd}`;
        }

        anchorLogs.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(res);
        anchorLogs.target = '_blank';
        anchorLogs.click();
      } catch (e) {
        this.$store.commit('settings/SET_POPUP', {
          show: true,
          type: "error",
          message: "ダウンロードエラー"
        });
      } finally {
        state.loadingExport = false;
      }
    }

    return {
      state,
      paginate,
      convertScToTimeFormat,
      labelText,
      handleExportLog,
      columns,
      handleSearch,
      handleExport,
      v$,
      invalid,
      optionsFacilities,
      handleChangePerPage
    }
  },
  methods: {
    showDateTime(refValue) {
      this.$refs[refValue].$el._flatpickr.open()
    }
  }
}
</script>

<style scoped lang="scss">
.text-select {
  align-self: center;
  margin: 0;
  padding-left: 15px;
  box-shadow: inset;
}

.flex-6 {
  flex: 1 1 auto;
}

.text-style {
  font-size: 16px;
  color: var(--vz-heading-color);
}

.range-picker {
  top: 0;
  right: 0;
  height: 100%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0 10px;
  background-color: #ccc;
  cursor: pointer;
}

.gap-column {
  column-gap: 25px;
}

.multiselect-input ::v-deep {
  .multiselect-wrapper {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    min-height: calc(var(--ms-border-width, 1px)*2 + var(--ms-font-size, 1rem)*var(--ms-line-height, 1.375) + var(--ms-py, .5rem)*2 - 2px);
    outline: none;
    position: relative;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .for-sm {
    flex-direction: column-reverse;
  }
}

.card-header-radius {
  border-radius: 7px 7px 0 0;
  font-size: 16px;
  color: var(--vz-heading-color);
}

.error-text-show {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ed5e5e;
}
</style>
