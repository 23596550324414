<template>
    <Layout>
      <PageHeader :title="state.title" :items="state.items" />
      <Perday />
    </Layout>
</template>

<script>
import { reactive } from 'vue'
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import i18n from '@/i18n';
import Perday from './perday'

export default {
    components: {
        Layout,
        PageHeader,
        Perday
    },
    setup() {
        const state = reactive({
            title: "広告管理",
            items: [
                {
                    text: i18n.global.t('t-ads-management'),
                    active: true
                },
                {
                    text: i18n.global.t('advertising.history'),
                    active: true,
                },
            ],
            tabIdActive: 'total',
            loadingExport: false
        })

        /**
         * tabId: perday / total
         * @param {String} tabId
         */
        const onChangeTab = (tabId) => {
            state.tabIdActive = tabId;
        }

        return {
            state,
            onChangeTab
        }
    },
}
</script>
